<template>
    <div class="casehistory-main">
        <div class="mian-container">
            <div class="container-card">
                <p class="title">
                    {{medicalInfo.tenantName}}
                </p>
                <!-- <p class="title" v-if="medicalInfo.tenantName">
                    <span class="" v-for="(txt,index) in medicalInfo.tenantName" :key="index">{{txt}}</span>
                </p> -->
                <p class="case-typeName">
                    <img src="@/assets/img/hosp-icon.png" class="icon">
                    <span class="txt">{{$t("patient.casehistory")}}</span>
                </p>
                <div class="hosp-info">
                    <p class="bednumAndhospital" v-if="medicalInfo.type == 137002">
                        {{$t("patient.bednum")}}：{{medicalInfo.bedNumber}}
                    </p>
                    <p class="bednumAndhospital" v-if="medicalInfo.type == 137002">
                        {{$t("patient.hospital")}}：{{medicalInfo.hospitalNumber}}
                    </p>
                    <p class="bednumAndhospital nocontent" v-if="medicalInfo.type !== 137002">
                        
                    </p>
                    <p class="bednumAndhospital" v-if="medicalInfo.type !== 137002">
                        {{$t("patient.clinicNumber")}}：{{medicalInfo.clinicNumber}}
                    </p>
                </div>
                <div class="info-detail">
                    <div class="detail-item">
                        <p class="items">
                            {{$t("patient.patientName")}}：{{medicalInfo.patientName}}
                        </p>
                        <p class="items">
                            {{$t("patient.age")}}：{{medicalInfo.patientAge}}{{$t("patient.annum")}}
                        </p>
                        <p class="items">
                            {{$t("patient.gender")}}：{{medicalInfo.patientGender == 1?$t("patient.man"):$t("patient.woman")}}
                        </p>
                    </div>
                    <div class="detail-item" v-if="medicalInfo.type == 137002">
                        <p class="items">
                            {{$t("patient.department")}}：{{medicalInfo.deptName}}
                        </p>
                        <p class="items">
                            {{$t("patient.ward")}}：{{medicalInfo.zoneName}}
                        </p>
                    </div>
                     <div class="detail-item" v-if="medicalInfo.type == 137002">
                        <p class="items">
                            {{$t("patient.directordoctor")}}：{{medicalInfo.doctorName}}
                        </p>
                        <p class="items">
                            {{$t("patient.dutynurse")}}：{{medicalInfo.nurseName}}
                        </p>
                    </div>
                    <div class="detail-item" v-if="medicalInfo.type == 137002">
                        <p class="items">
                            {{$t("patient.hosptime")}}：{{getLocalMinutesTime(medicalInfo.inHospitalDt)}}
                        </p>
                    </div>
                    
                    <div class="detail-item" v-if="medicalInfo.type != 137002">
                        <p class="items">
                            {{$t("patient.clicpDictor")}}：{{medicalInfo.doctorName}}
                        </p>
                        <p class="items">
                            {{$t("patient.assistantdoctor")}}：{{medicalInfo.nurseName}}
                        </p>
                    </div>
                    
                    <div class="detail-item" v-if="medicalInfo.type != 137002">

                        <p class="items">
                            {{$t("patient.department")}}：{{medicalInfo.clinicDt}}
                        </p>
                    </div>
                    <div class="detail-item" v-if="medicalInfo.type != 137002">
                        <p class="items">
                            {{$t("patient.clinicTime",{time:""})}}{{getLocalMinutesTime(medicalInfo.clinicDt)}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="casehistory-form">
            <div class="form-item">
                <p class="lable">
                    <i class="icon"></i>
                    <span class="value">{{$t("patient.recordtime")}}</span>
                </p>
                <div class="form-input" @click="recordTimeShow = true">
                    <p class="input-value"  :class="{'placeholder':!addForm.traceDt}">{{addForm.traceDt||$t("patient.pleaseselect")}}</p>
                    <i class="icon"></i>
                </div>
            </div>
            
            <div class="form-item">
                <p class="lable">
                    <i class="icon"></i>
                    <span class="value">{{$t("patient.customTxt")}}</span>
                </p>
                <div class="form-input"  >
                    <!-- <p class="input-value"  :class="{'placeholder':!addForm.log}">{{addForm.log||$t("patient.pleaseTxt")}}</p> -->
                    <!-- <i class="icon"></i> -->
                    <van-field
                        v-model="addForm.log"
                        rows="1"
                        autosize
                        type="textarea"
                        :placeholder='$t("patient.pleaseTxt")'
                        />
                </div>
            </div>
            <div class="form-item">
                <van-uploader class="lable" accept="image/*" v-model="uploadimg" :multiple="true" :max-count="10000">
                    <div class="uploadbox">
                        <i class="icon"></i>
                        <p class="txt">{{$t("patient.uploadtxt")}}</p>
                    </div>
                </van-uploader>
            </div>
            <div class="form-btn" :class="{'dislablebtn':btndislable}" @click="submitForm">
                {{$t("patient.bindbtnconfirm")}}
            </div>
        </div>

        <my-overlay :show="recordTimeShow" :showField="'recordTimeShow'">
           <van-datetime-picker
                v-model="currentDate"
                type="datetime"
                slot="overBox"
                @confirm="confirmtime"
                :title='$t("patient.pleaseselect")+$t("patient.recordtime")'
                :min-date="minDate"
                :max-date="maxDate"
            />
        </my-overlay>
    </div>
</template>

<script>
import myOverlay from "@/components/myoverlay"
import { getLocalMinutesTime } from "@/utils/date";
import { medicalDetail, traceAdd, traceGet, traceUpdate } from "@/api/patient";
export default {
    components:{
        myOverlay
    },
    data(){
        return{
            minDate: new Date(new Date().getFullYear()-10, 0, 1),
            maxDate: new Date(),
            currentDate: new Date(),
            recordTimeShow:false,
            uploadimg:[],
            medicalid:this.$route.query.medicalId,
            caseId:this.$route.query.caseId,
            medicalInfo:{},
            getLocalMinutesTime,
            addForm:{
                traceDt:"",
                log:"",
                cusVisiable:1,
                type:138006,
                medicalRecordId:this.$route.query.medicalId,
                toUserId:this.$route.query.medicalId,
            }
        }
    },
    created(){
        
        document.title = this.$t("patient.casehistory")
        this.getMedicalData();
        if(this.caseId){
            this.getCasehistoryData();
        }
        this.addForm.traceDt = getLocalMinutesTime(new Date())
    },
    computed:{
        btndislable(){
            return !(this.addForm.traceDt && (this.addForm.log || this.uploadimg.length != 0));
        }
    },
    methods:{
        async getMedicalData(){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let result = await medicalDetail({id:this.medicalid});
            toast.close()
            if(result.data.errorCode == 0){
                this.medicalInfo = result.data.data;
            }
        },
        async getCasehistoryData(){
            let result = await traceGet({traceId:this.caseId});
            if(result.data.errorCode == 0){
                // this.addForm = result.data.data;
                this.addForm.log = result.data.data.traceLog;
                this.currentDate = new Date(result.data.data.traceCdt);
                this.addForm.traceDt = getLocalMinutesTime(result.data.data.traceCdt);
                if(result.data.data.traceImageUrls&&result.data.data.traceImageUrls.length!=0){
                    result.data.data.traceImageUrls.map(item=>{
                        if(item){
                            this.uploadimg.push({
                                url:item
                            })
                        }
                    })
                }
            }
        },
        confirmtime(e){
            this.recordTimeShow = false;
            this.addForm.traceDt = getLocalMinutesTime(e);
        },
        async  uploadOss(index){
            console.log();
            if(!this.uploadimg[index].url){
                let result = await this.$uploadAxios.post('upload/base64-upload', {data:this.uploadimg[index].content,fileName:this.uploadimg[index].file.name});
                if(result.errorCode == 0){
                    this.uploadimg[index].imgUrl = result.data;
                }
            }
            if(index < (this.uploadimg.length-1)){
                index++;
                await this.uploadOss(index)
            }
        },
        // 提交病历
        async submitForm(){
            if(!this.btndislable){
               const toast = this.$Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: this.$t("message.loadingTxt")
                });
                if(this.uploadimg.length != 0){
                    await this.uploadOss(0);
                };
                let imgUrls = [];
                for(let it in this.uploadimg){
                    imgUrls.push(this.uploadimg[it].imgUrl||this.uploadimg[it].url);
                }
                let datadd = {
                    ...this.addForm,
                    imgUrls
                }
                datadd.traceDt = new Date(this.addForm.traceDt.replace(/-/g, '/')).getTime();
                let result;
                if(this.caseId){
                    datadd.traceId = this.caseId;
                    result = await traceUpdate(datadd);
                }else{
                    result = await traceAdd(datadd);
                }
                toast.close();
                if(result.data.errorCode == 0){
                    this.$router.go(-1);
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.casehistory-main{
    min-height: 100%;
    overflow: auto;
    background-color: #fff;
    background-image: url("~@/assets/img/casehistory-detail-back.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100% auto;
    padding: 1rem 0.6rem;
    box-sizing: border-box;
    .mian-container{
        background: #FFFFFF;
        border: 0.02rem solid;
        border-image: linear-gradient(0deg, #DDDDDD, #F2F2F2) 10 10;
        box-shadow: 0 0.08rem 0.24rem 0 rgba(203, 219, 235, 0.55);
        border-radius: 0.32rem;
        position: relative;
        &::after,&::before{
            position: absolute;
            top: 0;
            transform: translate(0,-50%);
            left: 0.6rem;
            width: 0.2rem;
            content: ' ';
            height: 0.46rem;
            background: url("~@/assets/img/paper-clip-icon.png") no-repeat;
            background-size: 100% 100%;
        }
        &::before{
            left: unset;
            right: 0.6rem;
        }
        .container-card{
            background: #FFFFFF;
            border: 0.02rem solid;
            border-image: linear-gradient(0deg, #DDDDDD, #F2F2F2) 10 10;
            box-shadow: 0 0.08rem 0.24rem 0 rgba(203, 219, 235, 0.55);
            border-radius: 0.32rem;
            padding: 0.4rem;
            text-align: center;
            .title{
                font-size: 0.64rem;
                font-family: PingFang SC;
                font-weight: 400;
                color: #999999;
                margin: 0.2rem 0 0.6rem 0;
                text-align: center;
                letter-spacing: 0.2rem;
                // text-align: justify;
                // text-align-last: justify;
                // text-justify: inter-ideograph;
                // display: flex;
                // justify-content: space-between;
            }
            .case-typeName{
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 0 0.32rem 0;
                border-bottom:0.04rem solid #C7C8C9;
                .icon{
                    width: 0.8rem;
                    height: 0.8rem;
                    margin-right: 0.1rem;
                }
                .txt{
                    font-size: 0.84rem;
                    font-family: PingFang SC;
                    font-weight: 600;
                    color: #333333; 
                }
            }
            .hosp-info{
                display: flex;
                justify-content: space-between;
                padding: 0.6rem 0 0.32rem 0;
                align-items: center;
                .bednumAndhospital{
                    padding-left: 0.5rem;
                    font-size: 0.52rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    position: relative;
                    &::after{
                        top: 50%;
                        left: 0;
                        transform: translate(0,-50%);
                        position: absolute;
                        width: 0.24rem;
                        height: 0.24rem;
                        border-radius: 50%;
                        content: ' ';
                        background: #49A3FF;
                    }
                }
                .nocontent::after{
                    display: none;
                }
            }
            .info-detail{
                background: #F5F6F9;
                padding: 0.4rem;
                .detail-item{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 0.5rem;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    .items{
                        font-size: 0.52rem;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #333333;
                    }
                }
            }
        }
    }
    .casehistory-form{
        .form-item{
            padding: 0.8rem 0;
            border-bottom: 0.02rem solid #E5E7EC;
            &:nth-last-child(2){
                border: 0;
            }
            .lable{
                display: flex;
                align-items: center;
                .icon{
                    width: 0.36rem;
                    height: 0.36rem;
                    margin-right: 0.2rem;
                    
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #49A3FF;
                    border-radius: 50%;
                    &::after{
                        width: 0.18rem;
                        height: 0.18rem;
                        content: ' ';
                        border-radius: 50%;
                        background: #fff;
                    }
                }
                .value{
                    font-size: 0.64rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    
                }
                .uploadbox{ 
                    width: 4rem;
                    height: 4rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #EDEEF2;
                    flex-direction: column;
                    .icon {
                        background: url("~@/assets/img/uploadimg-icon.png") no-repeat;
                        background-size: 100% 100%;
                        width: 1.6rem;
                        border-radius: unset;
                        margin-right: 0;
                        height: 1.32rem;
                        margin-bottom: 0.3rem;
                    }
                    .txt{
                        font-size: 0.52rem;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #999999;
                    }
                }
                /deep/ .van-image {
                    width: 4rem;
                    height: 4rem;
                }
                /deep/ .van-uploader__preview-delete{
                    width: 0.6rem;
                    height: 0.6rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .van-icon {
                        position: unset;
                        transform: scale(1);
                        font-size: 0.4rem;
                    }
                }
            }
            .form-input{
                padding-top: 0.6rem;
                display: flex;
                align-items: center;
                /deep/ .van-field{
                    padding: 0;
                    font-size: 0.64rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #333;
                    line-height: inherit;
                    .van-field__control{
                        height: auto;
                    }
                }
                .input-value{
                    font-size: 0.64rem;
                    font-family: PingFang SC;
                    font-weight: 500;
                    flex: 1;
                    color: #333333;
                }
                .placeholder{
                    color: #999;
                    font-weight: 300;
                }
                .icon{
                    flex: 0 0 auto;
                    margin-left: 0.2rem;
                    width: 0.28rem;
                    height: 0.52rem;
                    background: url("~@/assets/img/more-icon.png") no-repeat;
                    background-size: 100% 100%;
                }
            }
        }
        .form-btn{
            width: 10rem;
            height: 2rem;
            background: linear-gradient(0deg, #499FFF 0%, #49BFFF 100%);
            border-radius: 1rem;
            color: #fff;
            margin: 0.8rem auto;
            line-height: 2rem;
            text-align: center;
            font-size: 0.76rem;
            border: 0;
        }
        .dislablebtn{
            background: #C1C4CB;
            color: #919398;
        }
    }
}
</style>